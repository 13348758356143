<template>
  <div>
    <firmaBilgi
      :firm-id="FirmInfoId"
      :user-object="userObj"
    />
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Adresler</span>
        </template>
        <tabAdresler :firm-id="FirmInfoId" />
      </b-tab>
      <b-tab active>
        <template #title>
          <feather-icon icon="ToolIcon" />
          <span>İstihdam</span>
        </template>
        <tabIstihdam :firm-id="FirmInfoId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Mesai Ve Sosyal Haklar</span>
        </template>
        <tabSosyalHaklar :firm-id="FirmInfoId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Personel Temin ve Geliştirme</span>
        </template>
        <tabPersonelTemin :firm-id="FirmInfoId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Staj ve Eğitim İmkanları</span>
        </template>
        <tabStajImkan :firm-id="FirmInfoId" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import firmaBilgi from '@/views/islemler/firma/components/FirmaBilgi.vue'
import tabAdresler from '@/views/islemler/firma/components/FirmaTabAdresler.vue'
import tabIstihdam from '@/views/islemler/firma/components/FirmaTabIstihdam.vue'
import tabSosyalHaklar from '@/views/islemler/firma/components/FirmaTabSosyalHaklar.vue'
import tabPersonelTemin from '@/views/islemler/firma/components/FirmaTabPersonelTemin.vue'
import tabStajImkan from '@/views/islemler/firma/components/FirmaTabStajImkan.vue'

import * as auth from '@/utils/auth'
import * as apiFirm from '@/api/islemler/firminventory'

export default {
  components: {
    BTabs,
    BTab,
    firmaBilgi,
    tabAdresler,
    tabIstihdam,
    tabSosyalHaklar,
    tabPersonelTemin,
    tabStajImkan,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userObj: {},
      FirmInfoId: 0,
    }
  },
  async beforeCreate() {
    this.userObj = auth.getUserObject()
    await apiFirm.GetFirmByUserId(this.userObj.UserId).then(res => {
      if (res.data.entity === null) {
        this.FirmInfoId = 0
      } else {
        this.FirmInfoId = res.data.entity.firmInfoId
      }
    })
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-select.scss';
</style>
